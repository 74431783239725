import styled from 'styled-components';
import {
  COLORS,
  BREAKPOINTS,
  Logo as LogoBackground,
  MenuButton as MenuButtonBackground,
  H5,
  H6,
} from '../../utils/theme';

import IconPlus from '../IconPlus';

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled(LogoBackground)`
  background-position: 60px -100%;
  transition: background-position 0.4s;
  ${(props) =>
    props.visible &&
    `
      background-position: 60px 38px;
    `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    background-position: 20px -100%;

    ${(props) =>
      props.visible &&
      `
        background-position: 20px 20px;
      `}
  }
`;

export const MenuButton = styled(MenuButtonBackground)`
  background-position: calc(100% - 40px) -100%;
  transition: background-position 0.4s;
  ${(props) =>
    props.visible &&
    `
      background-position: calc(100% - 40px) 38px;
    `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    background-position: calc(100% - 20px) -100%;

    ${(props) =>
      props.visible &&
      `
          background-position: calc(100% - 20px) 20px;
        `}
  }
`;

export const MembersNumber = styled(H5)`
  line-height: 1.5;
  letter-spacing: 0.34px;
  color: ${COLORS.DARK_GREY};
  margin-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 64px;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 50px 16px;
  }
`;

export const Members = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
    flex: 1;
    flex-wrap: no-wrap;
  }
`;

export const Plus = styled(IconPlus)`
  width: 3.75rem;
  height: 3.75rem;
  margin-left: -1rem;
`;

export const MoreLoadRow = styled.button`
  cursor: pointer;
  display: inline-flex;
  max-width: max-content;
  align-items: center;
  padding: 0rem 1.7rem;
  border-radius: 3rem;
  margin-top: 32px;

  background: none;

  border: 1.5px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};

  &:focus-visible {
    outline: 2px solid ${COLORS.BLUE};
  }
  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
  }
`;

export const MoreLoadText = styled(H6)`
  margin-left: 15px;
  color: ${COLORS.BLUE};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
`;

import React, { useEffect, useState } from 'react';
import useScrollPosition from '@react-hook/window-scroll';

import { contentFetch, transformToContactMember } from '../../../lib/content-fetcher';
import { ContactCardListContainer, ContactCardListTitle } from './styles';
import { Members } from '../../MembersFiltered/styles';
import Member from '../../Member';
import qs from 'qs';

import { Logo, MenuButton } from '../../HeaderTitle/styles';

const memberQueryParams = qs.stringify(
  {
    populate: {
      image: {
        fields: ['formats', 'url'],
      },
    },
    fields: ['name', 'role', 'summary'],
  },
  {
    encodeValuesOnly: true,
  },
);

export const ContactCardList = ({ pathname, title, menuIsShown }) => {
  const [list, setList] = useState([]);
  const scrollY = useScrollPosition(60);
  const hide = scrollY < 120;

  useEffect(() => {
    contentFetch({
      pathname: pathname,
      setState: setList,
      transform: transformToContactMember,
      query: memberQueryParams,
    });
  }, [pathname]);

  return (
    <ContactCardListContainer>
      <Logo visible={!hide} />
      <MenuButton visible={!hide} menuIsShown={menuIsShown} />
      <ContactCardListTitle>{title}</ContactCardListTitle>
      {list.length ? (
        <Members>
          {list.map((item, index) => (
            <Member
              big
              summary
              fixedWidth
              hasMarginRight={index === 1}
              member={item}
              marginR={index % 2 === 0}
              marginL={index % 2 !== 0}
              marginB={index === 0 || index === 4}
              marginRBig={(index + 1) % 7 === 0}
            />
          ))}
        </Members>
      ) : null}
    </ContactCardListContainer>
  );
};

export default ContactCardList;
